.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Header-logos {
  margin: 0.5vw 0.5vh;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/**
 * Docs: Theme switcher
 */
.switcher {
  position: fixed;
  right: calc(var(--spacing) / 2 + var(--scrollbar-width, 0px));
  bottom: var(--spacing);
  width: auto;
  margin-bottom: 0;
  padding: 0.75rem;
  border-radius: 2rem;
  box-shadow: var(--card-box-shadow);
  line-height: 1;
  text-align: right;
}

.switcher::after {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border: 0.15rem solid currentColor;
  border-radius: 50%;
  background: linear-gradient(to right, currentColor 0%, currentColor 50%, transparent 50%);
  content: "";
  vertical-align: bottom;
  transition: transform var(--transition);
}

.switcher i {
  display: inline-block;
  max-width: 0;
  padding: 0;
  overflow: hidden;
  font-style: normal;
  font-size: 0.875rem;
  white-space: nowrap;
}

.switcher:hover,
.switcher:focus {
  max-width: 100%;
  transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
}

.switcher:hover::after {
  transform: rotate(180deg);
}

.switcher:hover i {
  max-width: 100%;
  padding: 0 calc(var(--spacing) / 2) 0 calc(var(--spacing) / 4);
  transition: max-width var(--transition), padding var(--transition);
}

.switcher:focus {
  box-shadow: var(--card-box-shadow), 0 0 0 0.2rem var(--secondary-focus);
}

@media (min-width: 576px) {
  .switcher {
    right: calc(var(--spacing) + var(--scrollbar-width, 0px));
  }
}

article {
  /* light gray box shadow */
  /* box-shadow: [horizontal offset] [vertical offset] [blur radius] [optional spread radius] [color]; */
  box-shadow: 0rem 0.25rem 1rem rgba(128, 128, 128, 0.25), 0rem -0.25rem 1rem rgba(128, 128, 128, 0.25) !important;
}

.dashboard-card {
  width: calc(100% - var(--spacing));
  margin: calc(var(--spacing) / 2);
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .dashboard-card {
    width: calc(50% - var(--spacing));
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .dashboard-card {
    width: calc(33% - var(--spacing));
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .dashboard-card {
    width: calc(33% - var(--spacing));
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .dashboard-card {
    width: calc(33% - var(--spacing));
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .dashboard-card {
    width: calc(33% - var(--spacing));
  }
}

.clickable {
  cursor: pointer;
}

.blur-onhover {
  transition: all 0.3s ease-in-out;
}

article.dashboard-card.clickable.blur-onhover:hover {
  box-shadow: 0px 0px 40px 1px var(--color) !important;
  -webkit-box-shadow: 0px 0px 40px 1px var(--color) !important;
  -moz-box-shadow: 0px 0px 40px 1px var(--color) !important;
}

.fade-in {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.Medium-text-overflow{
  display: -webkit-box;
  height: 100%;
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.Medium-buttons {
  width: 3vw;
  height: 4vh;
  opacity: 20%;
}
.Medium-buttons:hover{
  opacity: 30%
}

.Medium-buttons-dark {
  width: 3vw;
  height: 4vh;
  opacity: 20%;
  filter: invert(100%);
}
.Medium-buttons-dark:hover{
  opacity: 30%
}

/* @media (hover: none) and (pointer: coarse) {
  .Medium-buttons, .Medium-buttons-dark {
      display: none;
  }
} */

.partnersContainer {
  margin-bottom: 3vh;
}

.trustedByDark {
  font-size: 2.5em;
  font-weight: bold;
  color: white;
  margin-bottom: 5vh;
  margin-top: 2vh;
}

.trustedBy {
  font-size: 2.5em;
  font-weight: bold;
  color: black;
  margin-bottom: 5vh;
  margin-top: 2vh;
}

.gridContainer {
  display: grid;
  justify-content: space-evenly;
  column-gap: 4vw;
  row-gap: 4vh;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto;

}

.gridItem {

  width: 14vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gridItemMoonwell {

  width: 14vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(./images/stars.webp);
}

.gridItem img {
  width: 100%;
  height: 100%;
}